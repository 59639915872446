import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { IonicVue } from '@ionic/vue'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import store from './store'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { App as capApp } from '@capacitor/app'

window.process = {
  env: {
    VUE_APP_FACEBOOK_APP_ID: process.env.VUE_APP_FACEBOOK_APP_ID,
    VUE_APP_FACEBOOK_API_VER: process.env.VUE_APP_FACEBOOK_API_VER,
  },
}

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}
const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebaseApp.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const storageRef = storage.ref()
const firebaseRef = firebase

export { auth, db, storage, storageRef, firebaseRef }
import '../public/assets/main.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/core.css'

import BaseLayout from '@/components/base/layouts/BaseLayout.vue'
import NavLayout from '@/components/base/layouts/NavLayout.vue'
import AuthLayout from '@/components/base/layouts/AuthLayout.vue'
import ModalBase from '@/components/base/modals/ModalBase.vue'

let app

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(IonicVue).use(router).use(store)

    app.component('base-layout', BaseLayout)
    app.component('auth-layout', AuthLayout)
    app.component('nav-layout', NavLayout)
    app.component('modal-base', ModalBase)

    router.isReady().then(() => {
      app.mount('#app')
    })

    capApp.addListener('appUrlOpen', function (event) {
      // Fn for deep links
      const domain = 'www.planthis.pl'
      const pathArray = event.url.split(domain)
      // The pathArray is now like ['https://www.planthis.pl, '/details/42']
      // Get the last element with pop()
      const appPath = pathArray.pop()
      if (appPath) {
        router.push(appPath)
      }
    })

    defineCustomElements(window)
  }
})
