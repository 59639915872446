<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
        </ion-buttons>
        <ion-title class="fira-font">{{ pageTitle }}</ion-title>
        <ion-buttons slot="end" v-show="$slots.actionsEnd">
          <slot name="actionsEnd"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <slot />
    </ion-content>
    <ion-footer v-if="$slots.footer">
      <ion-toolbar id="footer-toolbar">
        <slot name="footer"></slot>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonFooter,
  IonHeader,
} from '@ionic/vue'

export default {
  name: 'NavLayout',
  props: {
    pageTitle: {
      type: String,
    },
    pageDefaultBackLink: {
      type: String,
    },
  },
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonFooter,
    IonHeader,
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
ion-toolbar {
  --background: var(--ion-item-background);
}
#footer-toolbar {
  --background: none;
}
</style>
