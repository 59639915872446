<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons v-if="$route.path !== '/login'" slot="start">
          <ion-back-button default-href="/login"></ion-back-button>
        </ion-buttons>
        <ion-title
          class="ion-text-center fira-font"
          :style="$route.path !== '/login' ? 'padding-right:68px' : ''"
          style="font-size: 24px; padding-left: 0"
        >
          <ion-icon :icon="leafOutline"></ion-icon>
          Planthis
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <slot />
    </ion-content>
    <ion-footer v-if="$slots.footer">
      <ion-toolbar id="footer-toolbar">
        <slot name="footer"></slot>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { leafOutline } from 'ionicons/icons'

import {
  IonPage,
  IonContent,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonFooter,
  IonButtons,
  IonBackButton,
  IonHeader,
} from '@ionic/vue'

export default {
  name: 'AuthLayout',
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonFooter,
    IonButtons,
    IonBackButton,
    IonHeader,
  },
  data() {
    return {
      leafOutline,
    }
  },
}
</script>
<style scoped>
#footer-toolbar {
  --background: none;
}
</style>
