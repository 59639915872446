<template>
  <ion-modal
    :is-open="modal"
    :swipe-to-close="true"
    :breakpoints="[initialBreakpoint, 0.8, 0.9, 1]"
    :initialBreakpoint="initialBreakpoint"
    @didDismiss="$emit('close')"
  >
    <ion-toolbar>
      <ion-buttons slot="start" class="ion-padding-start" v-if="startIcon">
        <ion-icon slot="icon-only" style="width: 22px; height: 22px" :icon="startIcon"></ion-icon>
      </ion-buttons>
      <ion-title class="ion-padding-start fira-font">{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="$emit('close', false)">
          <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <slot />
  </ion-modal>
</template>

<script>
import { IonModal, IonButtons, IonIcon, IonTitle, IonButton, IonToolbar } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'

export default {
  name: 'ModalBase',
  components: { IonModal, IonButtons, IonIcon, IonTitle, IonButton, IonToolbar },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    startIcon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    initialPoint: {
      type: Number,
      default: 0.6,
    },
  },
  data() {
    return {
      initialBreakpoint: this.initialPoint,
      closeOutline,
    }
  },
}
</script>
<style scoped>
ion-toolbar {
  --background: var(--ion-color-secondary) !important;
}
</style>
