import { auth as authFirebase, db } from '../main'
import router from '../router/index'

const auth = {
  namespaced: true,
  state: {
    userData: null,
    userDataFromCollection: null,
  },
  mutations: {
    setUserData(state, user) {
      // //console.log(user)
      state.userData = user
    },
    setUserDataFromCollection(state, user) {
      // //console.log(user)
      state.userDataFromCollection = user
    },
    clearSessionData(state) {
      state.userData = null
      state.userDataFromCollection = null
    },
  },
  actions: {
    async logOut({ commit }) {
      //console.log('Wylogowanie')
      await authFirebase.signOut().then(() => {
        commit('clearSessionData')
        //console.log('Auth state:', state)
        router.push('/login')
      })
      // .catch((e) => {
      //   console.error(e)
      // })
    },
    // async getUserDataFromCollection(context) {
    //   await db
    //     .collection(`users`)
    //     .get()
    //     .then((snap) => {
    //       snap.docs.find((doc) => {
    //         if (doc.id == authFB.currentUser.uid)
    //           context.commit('setUserDataFromCollection', doc.data())
    //       })
    //     })
    //     .catch((e) => console.error(e))
    // },

    // Tworzenie kolekcji
    // await db
    //   .collection('users')
    //   .doc(user.uid)
    //   .collection('images')
    //   .add({
    //     image: url,
    //     // location: { lat: loc.coords.latitude, lon: loc.coords.longitude },
    //   })
    async getUserDataFromCollection({ commit }) {
      await db
        .collection(`users`)
        .doc(authFirebase.currentUser.uid)
        .get()
        .then((doc) => {
          commit('setUserDataFromCollection', doc.data())
        })
      // .catch((e) => console.error(e))
    },

    async updateUserDataFromCollection({ dispatch }, payload) {
      //TODO const collection ?
      const objToSend = {}

      payload.forEach((el) => {
        const key = el.key
        const value = el.value
        objToSend[key] = value
      })
      await db
        .collection(`users`)
        .doc(authFirebase.currentUser.uid)
        .update(objToSend)
        .then(() => {
          dispatch('getUserDataFromCollection')
        })
      // .catch((e) => console.error(e))
    },
  },
  getters: {
    userData(state) {
      if (state.userData) return state.userData
      else false
    },
    userAvatar(state) {
      if (state.userData) return state.userData.photoURL
    },
    userName(state) {
      if (state.userData) return state.userData.displayName
    },
    userEmail(state) {
      if (state.userData) return state.userData.email
    },
    userEmailVerified(state) {
      if (state.userData) return state.userData.emailVerified
      else false
    },
    accProvider(state) {
      if (state.userDataFromCollection) return state.userDataFromCollection.account_provider
    },
    preferedTheme(state) {
      if (state.userDataFromCollection) return state.userDataFromCollection.prefered_theme
    },
    lastLoginProvider(state) {
      if (state.userDataFromCollection) return state.userDataFromCollection.last_login_provider
    },
  },
}
export default auth
