import { createRouter, createWebHistory } from '@ionic/vue-router'
// import { auth } from '../main'
// import TabsPage from '../pages/TabsPage.vue'

// const guard = (to, from, next) => {
//   const user = auth.currentUser
//   try {
//     if (user !== null && user.emailVerified) {
//       // Jeśli user zalogowany i potwierdził email
//       next()
//     } else if (user && !user.emailVerified) {
//       // Jesli user zalogowany ale nie ma potwierdzonego email
//       next('/auth/verify-profile')
//     } else {
//       next('/login')
//     }
//   } catch (error) {
//     next('/login')
//   }
// }

// const deleteProfileGuard = (to, from, next) => {
//   // Usuwanie konta znajduje się w panelu logowania i w ustawieniach konta
//   const user = auth.currentUser
//   try {
//     if (user) {
//       next()
//     } else {
//       next('/login')
//     }
//   } catch (error) {
//     next('/login')
//   }
// }

const routes = [
  {
    path: '/:catchAll(.*)',
    component: () => import('@/pages/Maintance.vue'),
  },
  // {
  //   path: '/login',
  //   component: () => import('@/pages/auth/AuthenticationPage.vue'),
  // },
  // {
  //   path: '/login/password',
  //   component: () => import('@/pages/auth/ForgetPasswordPage.vue'),
  // },
  // {
  //   path: '/auth/action',
  //   component: () => import('@/pages/auth/AuthActionPage.vue'),
  // },
  // {
  //   name: 'VerifyProfilePage',
  //   path: '/auth/verify-profile',
  //   component: () => import('@/pages/auth/VerifyProfilePage.vue'),
  // },
  // {
  //   path: '/tabs/',
  //   component: TabsPage,
  //   children: [
  //     {
  //       path: '',
  //       redirect: 'forum',
  //     },
  //     {
  //       path: 'forum',
  //       component: () => import('@/pages/TabForumPage.vue'),
  //       beforeEnter: guard,
  //     },
  //     {
  //       path: 'my-posts',
  //       component: () => import('@/pages/TabMyPostsPage.vue'),
  //       beforeEnter: guard,
  //     },
  //   ],
  // },
  // {
  //   name: 'TabForumPage',
  //   path: '/tabs/forum',
  //   component: () => import('@/pages/TabForumPage.vue'),
  //   beforeEnter: guard,
  // },
  // {
  //   name: 'TabMyPostsPage',
  //   path: '/tabs/my-posts',
  //   component: () => import('@/pages/TabMyPostsPage.vue'),
  //   beforeEnter: guard,
  // },
  // {
  //   path: '/profile/overview',
  //   component: () => import('@/pages/profile/ProfileOverviewPage.vue'),
  //   beforeEnter: guard,
  // },
  // {
  //   path: '/profile/settings',
  //   component: () => import('@/pages/profile/ProfileSettingsPage.vue'),
  //   beforeEnter: guard,
  // },
  // {
  //   path: '/profile/delete',
  //   component: () => import('@/pages/profile/ProfileDeletePage.vue'),
  //   beforeEnter: deleteProfileGuard,
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
