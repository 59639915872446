<template>
  <ion-app>
    <ion-router-outlet id="main"></ion-router-outlet>
    <!-- <SideMenu /> -->
    <!-- Check prevent multiple components mounting  -->
    <!-- <SettingsMenu v-if="$store.state.main.settingsMenu" />
    <ion-loading
      v-if="$store.getters['main/loading']"
      spinner="circular"
      message="Proszę czekać..."
    >
    </ion-loading>
    <ion-toast
      v-if="$store.getters['main/toast']"
      :message="$store.getters['main/toast']['msg']"
      :color="$store.getters['main/toast']['color']"
      :duration="$store.getters['main/toast']['duration']"
      :is-open="$store.getters['main/toast']['open']"
      :icon="$store.getters['main/toast']['icon'] ? $store.getters['main/toast']['icon'] : ''"
      :position="
        $store.getters['main/toast']['position']
          ? $store.getters['main/toast']['position']
          : 'bottom'
      "
      @didDismiss="$store.state.main.toast.open = false"
    >
    </ion-toast> -->
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
// import { FacebookLogin } from '@capacitor-community/facebook-login'
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
// import { auth } from './main'
import { mapGetters } from 'vuex'
// import SideMenu from './components/side-menu/SideMenu.vue'
// import SettingsMenu from './components/settings-menu/SettingsMenu.vue'

export default {
  name: 'App',
  components: {
    // SideMenu,
    IonRouterOutlet,

    // SettingsMenu,
    IonApp,
    // IonLoading,
    // IonToast,
  },
  computed: {
    ...mapGetters(['auth/preferedTheme']),
  },
  watch: {
    'auth/preferedTheme': {
      handler(val) {
        this.setTheme(val)
      },
      immediate: true,
    },
  },
  // created() {
  //   if (!this.desktop && !this.mobileweb) {
  //     this.googleInit()
  //     this.facebookInit()
  //   }
  //   if (auth.currentUser) {
  //     this.$store.commit('auth/setUserData', auth.currentUser)
  //     this.$store.dispatch('auth/getUserDataFromCollection')
  //   }
  // },
  methods: {
    //   googleInit() {
    //     GoogleAuth.initialize({
    //       clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID + '.apps.googleusercontent.com',
    //       grantOfflineAccess: true,
    //       scopes: ['profile', 'email'],
    //     })
    //   },
    //   async facebookInit() {
    //     await FacebookLogin.initialize({
    //       appId: process.env.VUE_APP_FACEBOOK_APP_ID,
    //       version: process.env.VUE_APP_FACEBOOK_API_VER,
    //     })
    //   },
    setTheme(theme) {
      // Set user theme
      switch (theme) {
        case 'dark':
          document.body.classList.toggle('dark', true)
          break
        case 'light':
          document.body.classList.toggle('dark', false)
          break
        default:
          document.body.classList.toggle('dark', true)
      }
    },
  },
}
</script>
