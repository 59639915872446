import Vuex from 'vuex'
import auth from './auth'
import main from './main'

export default new Vuex.Store({
  modules: {
    auth,
    main,
    // other modules here ...
  },
})
