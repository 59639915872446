<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button menu="side" style="color: white"></ion-menu-button>
        </ion-buttons>
        <ion-title
          class="ion-text-center fira-font"
          style="padding-right: 70px; font-size: 24px; color: white"
        >
          <div class="flex flex-justify-center flex-align-center">
            <ion-icon v-if="icon" :icon="icon" style="margin-right: 5px"></ion-icon>
            {{ title }}
          </div>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonHeader,
} from '@ionic/vue'

export default {
  name: 'BaseLayout',
  props: {
    title: {
      type: String,
      default: 'Planthis',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonMenuButton,
    IonIcon,
    IonHeader,
  },
}
</script>
