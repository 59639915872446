const main = {
  namespaced: true,
  state: {
    loading: false,
    settingsMenu: false,
    refreshSideMenuKey: 0,
    toast: {
      msg: '',
      color: '',
      duration: 4000,
      icon: '',
      open: false,
    },
  },
  mutations: {
    setToast(state, toastObj) {
      state.toast = {
        ...toastObj,
        open: true,
      }
    },
    setLoading(state, value) {
      state.loading = value
    },
    createSettingsMenu(state, value) {
      state.settingsMenu = value
    },
  },
  actions: {},
  getters: {
    loading(state) {
      return state.loading
    },
    toast(state) {
      return state.toast
    },
  },
}
export default main
